//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pigInfos, feedList, getToken, ranchData } from '../../common/api'
import { WebView } from '../../common/app'
import EZUIKit from 'ezuikit-js'

export default {
  data() {
    return {
      active: 1,
      pigDetal: {},
      feedList: [],
      isNull: false,
      fileShow: true,
      isShow: false,
    }
  },
  created() {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      console.log('Safari')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log('进入ios')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else {
      console.log('进入安卓')
      this.isShow = true
    }
    console.log('id', this.$store.state.access_id)
    WebView.playground = this.playground

    this.playground()
  },
  methods: {
    // 开关
    async ranch() {
      let res = await ranchData()
      console.log(res.data.data.ranch_switch)
      let num = res.data.data.ranch_switch
      if (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
      ) {
        console.log('Safari')
        if (num == 0) {
          this.isShow = false
        } else {
          this.isShow = true
        }
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        console.log('进入ios')
        if (num == 0) {
          this.isShow = false
        } else {
          this.isShow = true
        }
      } else {
        console.log('进入安卓')

        this.isShow = true
      }
    },
    playground(pidAndAccessId) {
      let all = pidAndAccessId.split('And')
      const accessId = all[1]
      const pid = all[0]
      console.log(accessId)
      // this.$store.commit("SET_P_ID", "137");
      // this.$store.commit("SET_ACCESS_ID", accessId);
      this.$store.commit('SET_P_ID', pid)
      this.initDetail()
      this.recordJl()
      setTimeout(() => {
        this.getToken()
      }, 300)
    },
    //获取猪崽档案
    async initDetail() {
      let data = {
        p_id: this.$store.state.p_id,
      }
      let res = await pigInfos(data)
      if (res.data.code != 200) return
      console.log(res.data.data, '档案')
      this.pigDetal = res.data.data
    },

    //喂养记录
    async recordJl() {
      let data = {
        ls_id: this.$store.state.p_id,
        page: 1,
        days: 3,
      }
      let res = await feedList(data)
      console.log(res, '喂养记录')
      if (res.data.code == '4004') {
        console.log(1)
        this.isNull = true
        return
      }
      if (res.data.code != '200') return
      this.feedList = res.data.data
      console.log(this.feedList, 'this.feedList')
    },
    // 视频初始化
    async getToken() {
      // 发送 POST 请求
      const res = await getToken()
      if (res.data.code != 200) return
      this.accessToken = res.data.data.accessToken
      console.log(this.accessToken)
      //视频初始化
      console.log(this.url, 'this.urlthis.url')
      this.player = await new EZUIKit.EZUIKitPlayer({
        autoplay: true, //自动播放
        id: 'video-container', // 视频容器ID
        accessToken: this.accessToken,
        url: this.pigDetal.monitor_url,
        audio: 0,
      })
      this.timer = setInterval(() => {
        this.timing += 60000
        if (this.timing >= 600000) {
          this.stopVideo()
          console.log('结束播放')
          this.networkAnomaly = true
          clearInterval(this.timer)
          return
        }
      }, 60000)
    },
    // 停止播放
    stopVideo() {
      this.player.stop()
    },
    close() {
      console.log('点击关闭')
      if (this.$route.query.planform == 'h5') {
        history.go(-1)
      } else {
        window.location.href = 'uniwebview://closeFeed?test=closeFeed'
      }
    },
  },
}
